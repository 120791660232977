
















import { Component, Vue, Prop } from 'vue-property-decorator';

const DEFAULT_FILL = 'var(--primarycolor-dark)';

@Component
export default class Favorite extends Vue {
  @Prop({ default: DEFAULT_FILL }) fill!: string;

}
